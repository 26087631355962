import { useState } from 'react';
import Dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ClassIcon from '@mui/icons-material/Class';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeleteIcon from '@mui/icons-material/Delete';
import DevicesIcon from '@mui/icons-material/Devices';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import SupportIcon from '@mui/icons-material/ContactSupport';

import CopyRightComponent from 'components/MenuComponents/CopyRightComponent';
import LoggedMobileComponent from 'components/AuthComponents/LoggedComponent';
import LoginButtonComponent from 'components/AuthComponents/LoginButtonComponent';
import Logo from 'components/Logo';
import MenuNav from 'components/Shared/Header/MenuNav';
import MenuNavList from 'components/Shared/Header/MenuNavList';
import SalesExecutivesMenuComponent from 'components/Shared/Header/SalesExecutivesMenuComponent';

import LoggedDeskComponent from 'components/AuthComponents/LoggedDeskComponent';
import LoginComponent from 'components/AuthComponents/LoginComponent';
import MenuItemWithIconSkeleton from 'components/MenuComponents/MenuItemWithIconSkeleton';

import { sideMenuLinks, sideMenuLinksToKids } from 'mocks/sideMenu';
import { DefaultMenuNav } from 'mocks/menuNav';

import { useIOS, useWebApp } from 'providers/WebAppProvider';

const MenuItemWithIconComponent = Dynamic(
  () => import('components/MenuComponents/MenuItemWithIconComponent'),
  {
    ssr: false,
    loading: () => <MenuItemWithIconSkeleton />,
  }
);

SideMenuComponent.propTypes = {
  menuNavHeader: PropTypes.instanceOf(Array),
  menuNavSideMenu: PropTypes.instanceOf(Array),
  setOpenModal: PropTypes.func,
  user: PropTypes.object,
  permissions: PropTypes.object,
};

export default function SideMenuComponent({
  menuNavHeader = DefaultMenuNav,
  menuNavSideMenu = DefaultMenuNav,
  setOpenModal = () => {},
  user = null,
  permissions = {
    isGroupManager: false,
    isKids: false,
    isLicenseManager: false,
    isMeetingManager: false,
    isSalesExecutives: false,
    isStudentManager: false,
    isTalker: false,
    isTeacher: false,
  },
}) {
  const runningInWebView = useWebApp();
  const runningInIOS = useIOS();
  const theme = useTheme();
  const deskBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [isSelect, setIsSelect] = useState(false);

  const {
    isCourseManager,
    isGroupManager,
    isKids,
    isLicenseManager,
    isMeetingManager,
    isSalesExecutives,
    isStudentManager,
    isTalker,
    isTeacher,
  } = permissions;

  const toggleDrawer = (side, openMenu) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    )
      return;

    setState({ ...state, [side]: openMenu });
  };

  const handleToggleDrawerOnSelectItem = () => {
    if (isSelect) {
      return toggleDrawer('right', true);
    }

    toggleDrawer('right', false);
  };

  return (
    <>
      {deskBreakpoint ? (
        <Grid container alignItems="center">
          <Grid item>
            <MenuNav menuNavLinks={menuNavHeader} />
          </Grid>
          <Grid item>
            <Box ml={3}>
              {user ? (
                <LoggedDeskComponent
                  user={user}
                  toggleMenu={toggleDrawer('right', true)}
                />
              ) : (
                <LoginComponent />
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <IconButton
          edge="start"
          onClick={toggleDrawer('right', true)}
          color="inherit"
          aria-label="menu"
          size="large"
        >
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
      >
        <Box
          role="presentation"
          onClick={() => {
            setIsSelect(false);
            handleToggleDrawerOnSelectItem();
          }}
          onKeyDown={() => {
            setIsSelect(false);
            handleToggleDrawerOnSelectItem();
          }}
        >
          <Box minWidth={240} m={1}>
            <List
              sx={{
                backgroundColor: '#FFF',
                maxWidth: 360,
                width: '100%',
              }}
            >
              <ListItem
                sx={
                  user
                    ? {
                        borderRadius: 2,

                        '&:hover': {
                          backgroundColor: '#FCFAFA',
                        },
                      }
                    : {}
                }
              >
                <ListItemText>
                  {user ? (
                    <LoggedMobileComponent
                      isManager={isMeetingManager}
                      user={user}
                    />
                  ) : (
                    <LoginButtonComponent />
                  )}
                </ListItemText>
              </ListItem>

              {user && (isKids || runningInWebView) ? (
                <>
                  {sideMenuLinksToKids.map(
                    ({ Icon, href, id, text, isNewMenuItem }) => (
                      <MenuItemWithIconComponent
                        Icon={Icon}
                        href={href}
                        id={id}
                        isNewMenuItem={isNewMenuItem}
                        key={id}
                        text={text}
                      />
                    )
                  )}

                  <Divider />

                  {user && runningInWebView && runningInIOS && (
                    <MenuItemWithIconComponent
                      Icon={DeleteIcon}
                      href="https://help.fluencypass.com/como-excluir-minha-conta"
                      id="header-delete-account"
                      text="Excluir conta"
                    />
                  )}

                  <ListItem>
                    <ListItemText>
                      <Logo whiteLabelDisposition="vertical" />
                    </ListItemText>
                  </ListItem>
                </>
              ) : (
                <>
                  {isSalesExecutives && (
                    <SalesExecutivesMenuComponent
                      user={user}
                      setOpenModal={setOpenModal}
                    />
                  )}

                  {isTalker && (
                    <>
                      <MenuItemWithIconComponent
                        Icon={ClassIcon}
                        href="/account/talker/meetings"
                        id="header-account-my-classes"
                        text="Minhas aulas"
                      />

                      {isMeetingManager && (
                        <MenuItemWithIconComponent
                          Icon={GroupIcon}
                          href="/account/management/meetings"
                          id="header-meeting-management-menu"
                          text="Gestão de aulas"
                        />
                      )}

                      {isCourseManager && (
                        <MenuItemWithIconComponent
                          Icon={DevicesIcon}
                          href="/account/courses-management"
                          id="header-courses-management-menu"
                          text="Gestão de Cursos"
                        />
                      )}

                      <MenuItemWithIconComponent
                        Icon={BorderColorIcon}
                        href="/account/talker/activities"
                        id="header-account-activities"
                        text="Correção de atividades"
                      />

                      <Divider />
                    </>
                  )}

                  {isLicenseManager && (
                    <MenuItemWithIconComponent
                      Icon={AssignmentIcon}
                      href="/account/licenses"
                      id="header-account-licenses"
                      text="Gestão de licenças"
                    />
                  )}

                  {(isLicenseManager || isGroupManager || isTeacher) && (
                    <MenuItemWithIconComponent
                      Icon={SupportIcon}
                      href="/account?suporte=true"
                      id="header-edubasic-chat-link"
                      text="Suporte"
                    />
                  )}

                  {isGroupManager && (
                    <MenuItemWithIconComponent
                      Icon={DashboardIcon}
                      href="/account/progress-followup"
                      id="header-account-licenses-dashboard"
                      text="Relatórios"
                    />
                  )}

                  {(isLicenseManager || isGroupManager || isStudentManager) && (
                    <Divider />
                  )}

                  {isTeacher && (
                    <>
                      <MenuItemWithIconComponent
                        Icon={DashboardIcon}
                        href="/account/student-dashboard"
                        id="header-account-student-dashboard"
                        text="Relatório do aluno"
                      />

                      <Divider />
                    </>
                  )}

                  {user &&
                    sideMenuLinks.map(
                      ({ Icon, href, id, text, isNewMenuItem }) => (
                        <MenuItemWithIconComponent
                          Icon={Icon}
                          href={href}
                          id={id}
                          key={id}
                          text={text}
                          isNewMenuItem={isNewMenuItem}
                        />
                      )
                    )}

                  <Divider />

                  <ListItem>
                    <ListItemText>
                      <Logo whiteLabelDisposition="vertical" />
                    </ListItemText>
                  </ListItem>

                  <MenuNavList
                    isMobile
                    menuNavLinks={menuNavSideMenu}
                    setIsSelect={setIsSelect}
                  />

                  <Divider />

                  <ListItem>
                    <ListItemText>
                      <CopyRightComponent />
                    </ListItemText>
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
