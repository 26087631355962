import React from 'react';
import { Box, Typography } from '@mui/material';
import CustomLink from 'components/Buttons/CustomLinkComponent';

function CopyRightComponent() {
  return (
    <>
      <Box my={2}>
        <CustomLink color="inherit" href="/privacy" underline="always">
          <Typography variant="caption" component="div">
            Política de privacidade
          </Typography>
        </CustomLink>
      </Box>
      <Box my={2}>
        <CustomLink color="inherit" href="/abroad-terms" underline="always">
          <Typography variant="caption" component="div">
            Termos de uso (Intercâmbio)
          </Typography>
        </CustomLink>
      </Box>
      <Box my={2}>
        <CustomLink
          color="inherit"
          href="/subscription-terms"
          underline="always"
        >
          <Typography
            variant="caption"
            underline="always"
            color="textPrimary"
            component="div"
          >
            Termos de uso (Assinatura)
          </Typography>
        </CustomLink>
      </Box>
    </>
  );
}

export default CopyRightComponent;
