import { Typography, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

import CustomLink from 'components/Buttons/CustomLinkComponent';
import SelectComponent from 'components/Shared/Header/MenuNavList/SelectComponent';

import { DefaultMenuNav } from 'mocks/menuNav';

MenuNavList.propTypes = {
  menuNavLinks: PropTypes.instanceOf(Array),
  setIsSelect: PropTypes.func.isRequired,
};

export default function MenuNavList({
  setIsSelect,
  menuNavLinks = DefaultMenuNav,
}) {
  return menuNavLinks.map((navLink, index) => {
    if (navLink?.isSelect) {
      return (
        <SelectComponent
          index={index}
          key={`key-${navLink.href}-${index}`}
          navLink={navLink}
          setIsSelect={setIsSelect}
        />
      );
    }

    return (
      <ListItem
        id={`header-nav-link-${index}`}
        key={`key-${navLink.href}-${index}`}
      >
        <ListItemText>
          <CustomLink
            id={`header-link-${index}`}
            key={`key-${navLink.href}-${index}`}
            href={navLink.href}
            title={navLink.text}
            color="text.primary"
            onClick={(evt) => {
              if (navLink.isAnchor) {
                const target = document.querySelector(navLink.href);

                if (target) {
                  target.scrollIntoView({ behavior: 'smooth' });
                }

                evt.preventDefault();
              }
            }}
          >
            <Typography variant="body2" fontWeight={600} component="span">
              {navLink.text}
            </Typography>
          </CustomLink>
        </ListItemText>
      </ListItem>
    );
  });
}
