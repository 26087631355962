import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import FlightIcon from '@mui/icons-material/Flight';
import PaymentIcon from '@mui/icons-material/Payment';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ForumIcon from '@mui/icons-material/Forum';
import ShareIcon from '@mui/icons-material/Share';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';

export const sideMenuLinks = [
  {
    Icon: HomeIcon,
    href: '/account',
    id: 'header-mobile-home-link',
    text: 'Início',
    isNewMenuItem: false,
  },
  {
    Icon: PhonelinkIcon,
    href: '/account/courses',
    id: 'header-mobile-courses-link',
    text: 'Meus cursos',
    isNewMenuItem: false,
  },
  {
    Icon: GroupIcon,
    href: '/account/group-talk',
    id: 'header-mobile-group-talk-link',
    text: 'Conversação',
    isNewMenuItem: false,
  },
  {
    Icon: SchoolIcon,
    href: '/account/private-talk',
    id: 'header-mobile-private-talk-link',
    text: 'Aulas particulares',
    isNewMenuItem: false,
  },
  {
    Icon: FlightIcon,
    href: '/account/abroad',
    id: 'header-mobile-abroad-link',
    text: 'Meus intercâmbios',
    isNewMenuItem: false,
  },
  {
    Icon: PersonIcon,
    href: '/account/profile',
    id: 'header-profile-link',
    text: 'Meu perfil',
    isNewMenuItem: false,
  },
  {
    Icon: PaymentIcon,
    href: '/account/mysubscription',
    id: 'header-mobile-mysubscription-link',
    text: 'Minha assinatura',
    isNewMenuItem: false,
  },
  {
    Icon: ShoppingBasketIcon,
    href: '/account/myorders',
    id: 'header-mobile-myorders-link',
    text: 'Minhas compras',
    isNewMenuItem: false,
  },
  {
    Icon: ForumIcon,
    href: 'https://questions.fluencypass.com/oauth2/callback',
    id: 'header-mobile-community-link',
    text: 'Comunidade',
    isNewMenuItem: false,
  },
  {
    Icon: ShareIcon,
    href: '/account/referral',
    id: 'header-mobile-referral-link',
    text: 'Indique seu amigo',
    isNewMenuItem: false,
  },
  {
    Icon: HelpOutlineIcon,
    href: 'https://help.fluencypass.com/',
    id: 'header-mobile-help-link',
    text: 'Ajuda',
    isNewMenuItem: false,
  },
  {
    Icon: LogoutIcon,
    href: '/Account/Logout',
    id: 'header-mobile-logout-link',
    text: 'Sair da conta',
    isNewMenuItem: false,
  },
];

export const sideMenuLinksToKids = [
  {
    Icon: HomeIcon,
    href: '/account',
    id: 'header-mobile-home-link',
    text: 'Início',
    isNewMenuItem: false,
  },
  {
    Icon: PhonelinkIcon,
    href: '/account/courses',
    id: 'header-mobile-courses-link',
    text: 'Meus cursos',
    isNewMenuItem: false,
  },
  {
    Icon: GroupIcon,
    href: '/account/group-talk',
    id: 'header-mobile-group-talk-link',
    text: 'Conversação',
    isNewMenuItem: false,
  },
  {
    Icon: SchoolIcon,
    href: '/account/private-talk',
    id: 'header-mobile-private-talk-link',
    text: 'Aulas particulares',
    isNewMenuItem: false,
  },
  {
    Icon: PersonIcon,
    href: '/account/profile',
    id: 'header-profile-link',
    text: 'Meu perfil',
    isNewMenuItem: false,
  },
  {
    Icon: ForumIcon,
    href: 'https://questions.fluencypass.com/oauth2/callback',
    id: 'header-mobile-community-link',
    text: 'Comunidade',
    isNewMenuItem: false,
  },
  {
    Icon: LogoutIcon,
    href: '/Account/Logout',
    id: 'header-mobile-logout-link',
    text: 'Sair da conta',
    isNewMenuItem: false,
  },
];
