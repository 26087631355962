import { Box, Button, Divider, Link, Typography } from '@mui/material';
import { useSearchParams } from 'next/navigation';

export default function LoginButtonComponent() {
  const searchParams = useSearchParams();
  const showChildLogin = searchParams.get('show-child-login');

  return (
    <Box component="span" p={{ xs: 0, md: 2 }}>
      <Typography variant="body2" fontWeight={700}>
        Acesse sua conta
      </Typography>

      <Button
        color="primary"
        fullWidth
        href="/login"
        sx={{ my: 2 }}
        variant="contained"
      >
        Entrar
      </Button>

      <Box display="flex" alignItems="center">
        <Typography variant="body2">Não tem conta?</Typography>

        <Link
          id="create-account-anchor"
          href={`${process.env.AUTH0_ISSUER_BASE_URL}/protocol/openid-connect/auth?client_id=${process.env.AUTH0_CLIENT_ID}&response_type=code&prompt=consent&redirect_to_signup=true&redirect_uri=${process.env.HOST}/setup/intro?connection=google-oauth2&scope=openid%20profile&screen_hint=signup`}
          variant="subtitle2"
          underline="none"
          sx={{
            fontWeight: 700,
            ml: 0.5,
            textDecoration: { xs: 'underline', md: 'none' },
          }}
        >
          Criar conta
        </Link>
      </Box>
      {showChildLogin && (
        <>
          <Divider sx={{ my: 2 }} />
          <Button
            color="primary"
            fullWidth
            href="/edubasic/auth"
            variant="contained"
            sx={{ mb: { xs: 1, md: 0 } }}
          >
            Criança
          </Button>
        </>
      )}
    </Box>
  );
}
