import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CustomLink from 'components/Buttons/CustomLinkComponent';

SelectComponent.propTypes = {
  index: PropTypes.number.isRequired,
  navLink: PropTypes.instanceOf(Object),
  setIsSelect: PropTypes.func.isRequired,
};

export default function SelectComponent({ navLink, index, setIsSelect }) {
  const [openLinks, setOpenLinks] = useState(false);

  return (
    <ListItem
      id={`header-nav-link-${index}`}
      key={`key-${navLink.href}-${index}`}
    >
      <ListItemText>
        <Box
          onClick={(evt) => {
            if (navLink.isAnchor) {
              const target = document.querySelector(navLink.href);

              if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
              }

              evt.preventDefault();
            }

            setIsSelect(true);
            setOpenLinks((prev) => !prev);
          }}
          sx={{
            alignItems: 'center',
            display: 'flex',
            ':hover': { cursor: 'pointer', textDecoration: 'underline' },
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            {navLink.text}
          </Typography>

          <ExpandMoreIcon
            sx={{
              ml: 1,
              transform: openLinks ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.4s ease',
            }}
          />
        </Box>

        <Collapse in={openLinks}>
          <List>
            {navLink.subLinks.map((destiny, _index) => (
              <CustomLink
                color="text.primary"
                href={destiny.href}
                id={`header-link-${_index}`}
                key={`subCustomLink-${_index}`}
              >
                <Typography fontWeight={600} mt={2} variant="body2">
                  {destiny.text}
                </Typography>
              </CustomLink>
            ))}
          </List>
        </Collapse>
      </ListItemText>
    </ListItem>
  );
}
