import React, { useRef, useState, useEffect } from 'react';
import { Box, Popper, Grow, Paper } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMore from '@mui/icons-material/ExpandMore';

import LoginButtonComponent from 'components/AuthComponents/LoginButtonComponent';
import CustomLink from 'components/Buttons/CustomLinkComponent';

function LoginComponent() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  useEffect(() => {
    if (!mainMenu && !subMenu) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMenu, subMenu]);

  return (
    <>
      <CustomLink
        id="header-login-link"
        title="Acesse sua conta"
        href="/login"
        color="textPrimary"
        underline="none"
      >
        <Box
          component="span"
          display="flex"
          alignItems="center"
          border={1}
          borderColor="border.main"
          borderRadius="8px"
          p={1}
          ref={anchorRef}
          onMouseEnter={() => {
            setMainMenu(true);

            setOpen(true);
          }}
          onMouseLeave={() => {
            setMainMenu(false);
          }}
        >
          <AccountCircleIcon />
          <Box fontWeight={600} ml={1}>
            Conta
          </Box>
          <ExpandMore />
        </Box>
      </CustomLink>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              onMouseEnter={() => {
                setSubMenu(true);
              }}
              onMouseLeave={() => {
                setSubMenu(false);
              }}
            >
              <Box pt={1} pb={2} minWidth={240} px={2}>
                <LoginButtonComponent />
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default LoginComponent;
