import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import LoggedComponent from 'components/AuthComponents/LoggedComponent';

LoggedDeskComponent.propTypes = {
  user: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default function LoggedDeskComponent({ user, toggleMenu }) {
  const anchorRef = useRef(null);

  return (
    user && (
      <Box
        component="span"
        display="flex"
        alignItems="center"
        border={1}
        borderColor="border.main"
        borderRadius="8px"
        py={0.5}
        px={1}
        ref={anchorRef}
        ml={1}
      >
        <LoggedComponent toggleMenu={toggleMenu} user={user} />
      </Box>
    )
  );
}
