import { ListItem, Skeleton } from '@mui/material';

function MenuItemWithIconSkeleton() {
  return (
    <ListItem>
      <Skeleton variant="rectangular" width="200px" height="32px" />
    </ListItem>
  );
}

export default MenuItemWithIconSkeleton;
