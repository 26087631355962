import PropTypes from 'prop-types';
import { Box, Avatar, Typography, Grid } from '@mui/material';

import CustomLink from 'components/Buttons/CustomLinkComponent';

import { getUserName } from 'utils/userUtils';

const Styles = {
  avatarSize: {
    width: (theme) => theme.spacing(5),
    height: (theme) => theme.spacing(5),
  },
};

LoggedComponent.propTypes = {
  user: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func,
};

export default function LoggedComponent({ user, toggleMenu = () => {} }) {
  return (
    <Box component="span">
      <CustomLink
        onMouseOver={toggleMenu}
        color="text.primary"
        href="/account"
        id="header-avatar-account-link"
        underline="none"
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Box mr={0.5}>
              <Avatar
                alt={user.name}
                src={user.picture}
                sx={Styles.avatarSize}
              />
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              <Box fontWeight={600} component="span">
                {getUserName(user)}
              </Box>

              <Typography variant="caption" display="block">
                minha conta
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </CustomLink>
    </Box>
  );
}
