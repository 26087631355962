import Dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import HubIcon from '@mui/icons-material/Hub';
import StoreIcon from '@mui/icons-material/Store';
import SearchIcon from '@mui/icons-material/Search';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import MenuItemWithIconSkeleton from 'components/MenuComponents/MenuItemWithIconSkeleton';

const MenuItemWithIconComponent = Dynamic(
  () => import('components/MenuComponents/MenuItemWithIconComponent'),
  {
    ssr: false,
    loading: () => <MenuItemWithIconSkeleton />,
  }
);

const RatesButtonComponent = Dynamic(
  () =>
    import('components/SalesExecutivesComponents/ExchangeRateModalComponent'),
  { ssr: false }
);

function SalesExecutivesMenuComponent({ user, setOpenModal }) {
  return (
    <>
      <MenuItemWithIconComponent
        Icon={HubIcon}
        href={`https://app.hubspot.com/login/sso?email=${user.email}`}
        id="header-hubspot-link"
        rel="noopener noreferrer"
        target="blank"
        text="Hubspot"
      />

      <MenuItemWithIconComponent
        Icon={StoreIcon}
        href="/account/sales-executive/create-subscription"
        id="header-account-create-subscription"
        text="Criar assinatura"
      />

      <MenuItemWithIconComponent
        Icon={TravelExploreIcon}
        href="/schools"
        id="header-quote-abroad"
        text="Cotar intercâmbio"
      />

      <MenuItemWithIconComponent
        Icon={TravelExploreIcon}
        href="/packages"
        id="header-quote-packages"
        text="Cotar pacotes"
      />

      <RatesButtonComponent setOpenModal={setOpenModal} />

      <MenuItemWithIconComponent
        Icon={SearchIcon}
        href="/account/management/students"
        id="header-account-search-student"
        text="Buscar estudante"
      />

      <Divider />
    </>
  );
}

SalesExecutivesMenuComponent.propTypes = {
  user: PropTypes.object,
  setOpenModal: PropTypes.func,
};

SalesExecutivesMenuComponent.defaultProps = {
  user: null,
  setOpenModal: () => {},
};

export default SalesExecutivesMenuComponent;
