import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomLink from 'components/Buttons/CustomLinkComponent';
import SelectComponent from 'components/Shared/Header/MenuNav/SelectComponent';

import { DefaultMenuNav } from 'mocks/menuNav';

MenuNav.propTypes = {
  isMobile: PropTypes.bool,
  menuNavLinks: PropTypes.instanceOf(Array),
};

MenuNav.defaultProps = {
  isMobile: false,
  menuNavLinks: DefaultMenuNav,
};

export default function MenuNav({ isMobile, menuNavLinks }) {
  return (
    <Box
      component="nav"
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      gap={isMobile ? 3 : 4}
      p={isMobile ? 2 : 0}
    >
      {menuNavLinks.map((navLink, index) => {
        if (navLink?.isSelect) {
          return <SelectComponent key={`key-${index}`} navLink={navLink} />;
        }

        return (
          <CustomLink
            id={`header-link-${index}`}
            key={`key-${navLink.href}-${index}`}
            href={navLink.href}
            title={navLink.text}
            color="text.primary"
            onClick={(evt) => {
              if (navLink.isAnchor) {
                const target = document.querySelector(navLink.href);

                if (target) {
                  target.scrollIntoView({ behavior: 'smooth' });
                }

                evt.preventDefault();
              }
            }}
          >
            <Box fontWeight={600} component="span">
              {navLink.text}
            </Box>
          </CustomLink>
        );
      })}
    </Box>
  );
}
