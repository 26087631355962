import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grow, Paper, Popper, Typography } from '@mui/material';

import CustomLink from 'components/Buttons/CustomLinkComponent';

SelectComponent.propTypes = {
  navLink: PropTypes.instanceOf(Object).isRequired,
};

export default function SelectComponent({ navLink }) {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  useEffect(() => {
    if (!mainMenu && !subMenu) setOpen(false);
  }, [mainMenu, subMenu]);

  return (
    <>
      <Typography
        fontWeight={600}
        id="header-destinies-link"
        onMouseEnter={() => {
          setMainMenu(true);

          setOpen(true);
        }}
        onMouseLeave={() => {
          setMainMenu(false);
        }}
        onClick={(evt) => {
          if (navLink.isAnchor) {
            const target = document.querySelector(navLink.href);

            if (target) {
              target.scrollIntoView({ behavior: 'smooth' });
            }

            evt.preventDefault();
          }
        }}
        ref={anchorRef}
        sx={{ cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
        variant="body2"
      >
        {navLink.text}
      </Typography>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              onMouseEnter={() => {
                setSubMenu(true);
              }}
              onMouseLeave={() => {
                setSubMenu(false);
              }}
            >
              <Box pt={1} pb={2} minWidth={240} px={2}>
                {navLink.subLinks.map((destiny) => (
                  <CustomLink
                    color="text.primary"
                    href={destiny.href}
                    key={`destiny-${destiny.text}`}
                  >
                    <Typography fontWeight={600} mt={2} variant="body2">
                      {destiny.text}
                    </Typography>
                  </CustomLink>
                ))}
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
